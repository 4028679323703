<template>
  <section class="organism-room-price">
    <bg-card>
      <bg-text class="mb-16" size="heading-5"
        >Rincian Tipe Kamar dan Harga</bg-text
      >

      <div class="ss-table-container--overflow">
        <table class="ss-table">
          <thead>
            <tr>
              <th
                v-for="(text, i) in tableHeader"
                :key="`price-table-header-${i}`"
                class="ta-l"
              >
                <bg-text size="body-1">{{ text }}</bg-text>
              </th>
            </tr>
          </thead>
          <tbody v-if="loading">
            <tr>
              <td v-for="i in 15" :key="`skeleton-room-price-${i}`">
                <bg-skeleton width="100%" height="20px" />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="list in data" :key="`listing-list-${list.id}`">
              <td>{{ list.type | nullable }}</td>
              <td>{{ list.gender | nullable }}</td>
              <td>{{ list.total_rooms }}</td>
              <td>{{ list.ota_price_daily | rupiah }}</td>
              <td>{{ list.selling_monthly | rupiah }}</td>
              <td>{{ list.selling_quarterly | rupiah }}</td>
              <td>{{ list.selling_semiannually | rupiah }}</td>
              <td>{{ list.static_monthly | rupiah }}</td>
              <td>{{ list.static_quarterly | rupiah }}</td>
              <td>{{ list.static_semiannually | rupiah }}</td>
              <td>{{ list.publish_monthly | rupiah }}</td>
              <td>{{ list.publish_quarterly | rupiah }}</td>
              <td>{{ list.publish_semiannually | rupiah }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </bg-card>
  </section>
</template>

<script>
import { rupiahFormatter } from 'Utils/formatter';

import { BgCard, BgText, BgSkeleton } from 'bangul-vue';

export default {
  name: 'RoomPrice',

  components: {
    BgCard,
    BgText,
    BgSkeleton,
  },

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },

  filters: {
    nullable: value => value || '-',
    rupiah: value => {
      if (value !== null && Number.isFinite(value)) {
        return rupiahFormatter(value);
      }
      return '-';
    },
  },

  data() {
    return {
      tableHeader: [
        'Tipe Kamar',
        'Gender',
        'Jumlah Kamar',
        'Harga Static Harian (OTA)',
        'Harga Sewa Bulanan',
        'Harga Sewa 3 Bulan',
        'Harga Sewa 6 Bulan',
        'Harga Kerja Sama (Static) Bulanan',
        'Harga Kerja Sama (Static) 3 Bulan',
        'Harga Kerja Sama (Static) 6 Bulan',
        'Harga Kerja Sama (Publish) Bulanan',
        'Harga Kerja Sama (Publish) 3 Bulan',
        'Harga Kerja Sama (Publish) 6 Bulan',
      ],
    };
  },

  methods: {
    rupiahFormatter,
  },
};
</script>

<style lang="scss" src="./RoomPrice.scss" scoped />
